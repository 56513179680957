<template>
  <div
    v-loading="true"
    class="d-flex h-100 w-100 justify-content-center align-items-center full-screen"
  ></div>
</template>

<script>
export default {
  name: 'task-board-wrapper',
  mounted() {
    const { modalTaskId } = this.$route.query;
    const path = this.$route.path.replace('/tasks/board', '/hub');
    const stringQuery = modalTaskId ? `?taskId=${modalTaskId}` : '';
    window.location.href = `${window.location.origin}${path}${stringQuery}`;
  },
};
</script>

<style lang="scss" scoped>
.full-screen {
  height: 100vh !important;
}
</style>
