<template>
  <div class="title-wrapper">
    <h5 class="title">{{ $t(`filters.groups.facets.${name}`) }}</h5>
    <span class="reset-link" @click="handleClick">{{ $t(resetLabel) }}</span>
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      required: true,
    },
    field: {
      type: String,
      required: true,
    },
    value: {
      required: false,
    },
  },
  methods: {
    handleClick() {
      return this.field !== 'values' || !this.value.length
        ? this.$emit('reset')
        : this.$emit('unselect-all');
    },
  },
  computed: {
    resetLabel() {
      if (this.field !== 'values') return 'filters.drawer.reset';

      return this.value && this.value.length
        ? 'filters.drawer.unselect-all'
        : 'filters.drawer.select-all';
    },
  },
};
</script>

<style lang="scss" scoped>
.title-wrapper {
  display: flex;
  justify-content: space-between;

  .title {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    letter-spacing: 0.02em;
    color: #000000;
  }

  .reset-link {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.02em;
    text-decoration-line: underline;
    color: #7d89a0;
    cursor: pointer;
  }
}
</style>
