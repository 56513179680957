<template>
  <div :class="base">
    <div
      :class="`${base}__section`"
      v-for="(el, idx) in inputs.form"
      :key="el.uuid"
    >
      <component
        :class="[`${base}__section__item`, 'mb-2']"
        v-for="field in section"
        :is="field.type"
        :key="field.key"
        v-bind="field"
        :value="el[field.key]"
        @change="($evt) => setFormItem(idx, field.key, $evt)"
      />
      <font-awesome-icon
        :class="[`${base}__section__delete`]"
        :icon="['fal', 'times']"
        @click="removeFormItem(idx)"
      />
    </div>
    <div :class="`${base}__add`">
      <base-button-old
        size="sm"
        type="primary"
        :outline="true"
        @click="addFormItem()"
        >{{ $t(`${pre}.add`) }}</base-button-old
      >
    </div>
  </div>
</template>
<script>
import { v4 as uuid } from 'uuid';

import WorkflowInput from 'components/Workflow/WorkflowInput.vue';
import WorkflowSelect from 'components/Workflow/WorkflowSelect.vue';

const pre =
  'knowledge.diagnostic-builder.side-bar.workflow.inputs.set-context-from-form';

export default {
  name: 'workflow-set-context-from-form',
  components: {
    WorkflowInput,
    WorkflowSelect,
  },
  props: {
    workflowAction: {
      type: Object,
      default: () => ({}),
    },
    content: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    payload: {
      get() {
        return this.workflowAction.payload || {};
      },
      set(value) {
        this.$emit('change', { ...this.workflowAction, payload: value });
      },
    },
  },
  mounted() {
    this.syncInputs(this.payload);
  },
  data() {
    return {
      base: 'workflow-set-context-from-input',
      pre,
      section: [
        {
          title: this.$t(`${pre}.key.title`),
          placeholder: this.$t(`${pre}.key.placeholder`),
          key: 'key',
          icon: 'plug',
          type: 'WorkflowInput',
        },
        {
          title: this.$t(`${pre}.value.title`),
          placeholder: this.$t(`${pre}.value.placeholder`),
          key: 'value',
          icon: 'plug',
          type: 'WorkflowInput',
        },
      ],
      inputs: {
        form: [],
      },
    };
  },
  methods: {
    addFormItem() {
      this.inputs.form.push({ key: null, value: null, uuid: uuid() });
    },
    removeFormItem(idx) {
      this.inputs.form.splice(idx, 1);
      this.syncPayload();
    },
    setFormItem(idx, key, value) {
      this.inputs.form[idx][key] = value;
      this.syncPayload();
    },
    syncPayload() {
      // eslint-disable-next-line no-unused-vars
      const form = [...this.inputs.form].map(({ uuid, ...el }) => el);
      this.payload = Object.assign(this.payload, { form });
    },
    syncInputs(payload) {
      if (!payload.form) {
        this.addFormItem();
      } else {
        this.inputs.form = payload.form.map((el) => ({ ...el, uuid: uuid() }));
      }
    },
  },
  watch: {
    payload() {
      this.syncInputs(this.payload);
    },
  },
};
</script>
<style lang="scss" scoped>
.workflow-set-context-from-input {
  &__section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    &__item {
      flex: 12;
    }
    &__delete {
      flex: 1;
      margin-top: 16px;
      cursor: pointer;
      color: $grey-5-mayday;
      &:hover {
        color: $blue-mayday;
      }
    }
  }
  &__add {
    margin: 4px 4px;
  }
}
</style>
