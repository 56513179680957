<template>
  <div
    :class="['d-flex flex-row mt-1', isEmpty ? '' : element.deleteWrapperAlign]"
  >
    <div
      :class="[
        'img-placeholder-wrapper',
        { 'img-placeholder-wrapper-empty': isEmpty },
      ]"
      :style="[element.style, isEmpty ? element.emptyStyle : '']"
      @click="modals.img.display = true"
      @mouseover="$emit('hover', true)"
      @mouseleave="$emit('hover', false)"
    >
      <el-tooltip
        :content="
          $t('public-knowledge.appearances.items.brand.tooltip-download')
        "
        :disabled="isEmpty"
      >
        <img
          :src="elementValueDisplay"
          class="img-placeholder"
          :style="isEmpty ? element.imgEmptyStyle : ''"
      /></el-tooltip>
    </div>

    <div
      :class="[
        'd-flex flex-column ml-2 justify-content-between',
        { 'download-group': isEmpty },
      ]"
    >
      <base-button
        v-if="isEmpty"
        variant="primary"
        size="sm"
        @click="modals.img.display = true"
        :text="$t('public-knowledge.appearances.items.brand.button-download')"
      ></base-button>

      <el-popconfirm
        v-else
        :confirm-button-text="
          $t('public-knowledge.appearances.items.brand.confirm-delete-true')
        "
        :cancel-button-text="
          $t('public-knowledge.appearances.items.brand.confirm-delete-false')
        "
        icon="el-icon-info"
        icon-color="red"
        :title="$t('public-knowledge.appearances.items.brand.confirm-delete')"
        @confirm="$emit('delete-element', $event)"
      >
        <el-tooltip
          :content="
            $t('public-knowledge.appearances.items.brand.tooltip-delete')
          "
          slot="reference"
        >
          <base-button
            class="display-on-hover"
            :icon-only="true"
            icon="trash"
            variant="tertiary"
            size="sm"
          ></base-button> </el-tooltip
      ></el-popconfirm>

      <div v-if="isEmpty" class="img-input-advise">
        {{
          $t('public-knowledge.appearances.items.brand.download-advice', {
            width: element.size.width,
            height: element.size.height,
          })
        }}
      </div>
    </div>
    <IconEditionModal
      v-if="modals.img.display"
      :title="element.modalTitle"
      :description="element.modalDescription"
      :display="modals.img.display"
      :editionOptions="modals.img.editionOptions"
      :aspect-ratio="
        element.label === 'companyIconUri' ? null : modals.img.aspectRatio
      "
      custom-width="400px"
      custom-height="400px"
      @close="modals.img.display = false"
      @choice="newValue"
    />
  </div>
</template>

<script>
import IconEditionModal from 'components/Modals/IconEditionModal/IconEditionModal';
export default {
  name: 'ImageInput',
  components: {
    IconEditionModal,
  },
  data() {
    return {
      modals: {
        img: {
          display: false,
          editionOptions: {
            emoji: false,
            fa: false,
            upload: true,
            link: false,
          },
          aspectRatio: '',
        },
      },
    };
  },
  props: {
    element: Object,
    isEmpty: Boolean,
    elementValueDisplay: String,
  },
  mounted() {
    this.modals.img.aspectRatio =
      parseInt(this.element.size.width) / parseInt(this.element.size.height);
  },
  methods: {
    newValue(e) {
      this.$emit('new-element', e.value);
      this.modals.img.display = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.img-placeholder-wrapper {
  max-width: 107px;
  height: 50px;
  display: flex;
  align-items: center;
  padding: 8px;
}
.img-placeholder-wrapper-empty {
  width: 107px;
  background-color: $grey-1-mayday;
  border: 1px $grey-4-mayday dashed;
  justify-content: center;
  &:hover {
    border: 1px $blue-mayday dashed;
    cursor: pointer;
    background-color: $grey-1-mayday;
    filter: drop-shadow(0px 15px 15px rgba(112, 144, 176, 0.2));
  }
  &:hover .img-placeholder {
    transform: translate(2px) scale(1.3);
  }
}

.img-placeholder {
  max-width: 100%;
  max-height: 100%;
  cursor: pointer;
}

.img-button {
  padding: 4px 8px;
  border-radius: 2px;
  width: 80px;
}

.display-on-hover {
  display: none;
}

.delete-icon-wrapper {
  width: 24px;
  height: 24px;
  cursor: pointer;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background-color: $grey-2-mayday;
  }
}

.delete-icon {
  width: 10px;
  height: 12px;
  color: $grey-7-mayday;
}

.download-group {
  padding: 2px;
}
.img-input-advise {
  font-size: 12px;
  color: $grey-6-mayday;
}
</style>
