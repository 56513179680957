<template>
  <div
    v-loading="true"
    class="d-flex align-items-center justify-content-center"
    style="height: 100vh"
  >
    <h2>Vous allez être redirigé vers Mayday</h2>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import gqlService from '../utils/gqlService';

export default {
  name: 'RedirectSso',
  async mounted() {
    if (this.$route.query.code) {
      this.$router.push({
        path: '/login',
        query: { code: this.$route.query.code },
      });
    } else {
      const { sso_token, accessExpirationDate, redirect, sso_refresh } =
        this.$route.query;

      if (!sso_refresh) {
        this.$store.state.accessToken = sso_token;
        this.$store.state.accessExpirationDate = accessExpirationDate;
        gqlService.user.userLoginFromToken().then((response) => {
          this.$store.dispatch('loginFromToken', response);
          this.$router.push(redirect || '/home-v2');
        });
      } else {
        await this.loginFromSSO({
          accessToken: sso_token,
          refreshToken: sso_refresh,
          accessExpirationDate,
        });
        this.$router.push(redirect || '/home-v2');
      }
    }
  },
  methods: mapActions(['loginFromSSO']),
};
</script>

<style></style>
