<template>
  <div class="selfcare-widget">
    <div
      class="d-flex justify-content-center align-items-center flex-column p-4"
    >
      <h3>Position</h3>
      <el-select v-model="position" placeholder="Position">
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        ></el-option>
      </el-select>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'SelfcareWidget',
  data() {
    return {
      position: 'bottom-right',
      options: [
        {
          value: 'top-left',
          label: 'Top Left',
        },
        {
          value: 'top-right',
          label: 'Top Right',
        },
        {
          value: 'bottom-left',
          label: 'Bottom Left',
        },
        {
          value: 'bottom-right',
          label: 'Bottom Right',
        },
      ],
    };
  },
  computed: {
    scriptUrl() {
      return `https://${this.focusCompanyPublicConfig.hostname}/widget/md-selfcare.umd.js`;
    },

    ...mapGetters('publicKnowledgeModule', [
      'focusCompanyPublicConfig',
      'eventEmitterPlugins',
    ]),
  },
  methods: {
    handleEventListener({ id, event, data }, e) {
      // I leave this console.log() for better observability
      console.log({ id, event, data, e });
      const h = this.$createElement;
      this.$notify({
        title: 'Nouvel événement intercepté',
        message: h('ul', null, [
          h('li', null, `Plugin Id: ${id}`),
          h('li', null, `Event: ${event}`),
          h('li', null, `Wanted data: ${JSON.stringify(data)}`),
          h('li', null, `Receive data: ${JSON.stringify(e.detail)}`),
        ]),
        type: 'info',
        duration: 0,
        closable: true,
        customClass: 'custom-notification custom-notification-info',
      });
    },
    reloadScript() {
      window.__MAYDAY_SELFCARE__.init({
        accessKey: this.focusCompanyPublicConfig.embeddedAccessKey,
        locale: `${this.$i18n.locale}-${this.$i18n.locale}`,
        position: this.position,
      });
    },
    ...mapActions('publicKnowledgeModule', [
      'getFocusCompanyPublicConfigByKnowledgeId',
    ]),
  },
  async created() {
    const { knowledgeId } = this.$route.params;

    await this.getFocusCompanyPublicConfigByKnowledgeId(knowledgeId);

    const scriptEl = document.createElement('script');
    scriptEl.setAttribute('src', this.scriptUrl);
    document.body.append(scriptEl);

    this.eventEmitterPlugins.forEach((plugin) => {
      window.addEventListener(`${plugin.event}`, (e) => {
        this.handleEventListener(plugin, e);
      });
    });
  },
  mounted() {
    const l = this.$i18n.locale;
    window.addEventListener('MdSelfcareLoaded', (e) => {
      window.__MAYDAY_SELFCARE__ = e.detail;
      // 'init' is a md-selfcare function that init accessKey and locale in the store
      window.__MAYDAY_SELFCARE__.init({
        accessKey: this.focusCompanyPublicConfig.embeddedAccessKey,
        // No worries with en-en, it will be replaced with en-us or en-gb by 'guessLanguage'
        locale: `${l}-${l}`,
        position: this.position,
      });
    });
  },
  beforeDestroy() {
    const scriptEl = document.querySelector(`script[src="${this.scriptUrl}"]`);
    if (scriptEl) {
      scriptEl.remove();
    }
    window.removeEventListener('MdSelfcareUpdated', this.updatePath);
    this.eventEmitterPlugins.forEach((plugin) => {
      window.removeEventListener(`${plugin.event}`);
    });
  },
  watch: {
    position() {
      this.reloadScript();
    },
  },
};
</script>

<style lang="scss" scoped>
.selfcare-widget {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 2rem;
  font-weight: bold;
  width: 100%;
}
</style>
