<template>
  <div :class="base">
    <div :class="[`${base}__logic`, 'col-6', 'px-0']">
      <WorkflowSelect
        :title="$t(`${pre}.logic.title`)"
        icon="plug"
        :options="options"
        :placeholder="$t(`${pre}.logic.placeholder`)"
        :value="value"
        @change="setLogic($event)"
      />
    </div>
    <div :class="`${base}__conditions`">
      <WorkflowSectionTitle
        :class="`${base}__conditions__title`"
        :title="$t(`${pre}.conditions.title`)"
        icon="plug"
      />
      <WorkflowLogicRule
        v-for="(rule, idx) in logicRule.rules"
        :class="`${base}__conditions__rule`"
        :key="rule.id"
        :rule="rule"
        @change="($evt) => setRuleAtIdx(idx, $evt)"
        @remove="removeRuleAtIdx(idx)"
      />
      <div :class="`${base}__conditions__add`">
        <base-button-old
          size="sm"
          type="primary"
          :outline="true"
          @click="addRule()"
          >{{ $t(`${pre}.conditions.add`) }}</base-button-old
        >
      </div>
    </div>
  </div>
</template>
<script>
import WorkflowSectionTitle from '../WorkflowSectionTitle.vue';
import WorkflowLogicRule from './WorkflowLogicRule/WorkflowLogicRule.vue';
import WorkflowSelect from '../WorkflowSelect.vue';

const pre =
  'knowledge.diagnostic-builder.side-bar.workflow.inputs.auto-step-selection';

export default {
  name: 'workflow-logic',
  components: { WorkflowSelect, WorkflowLogicRule, WorkflowSectionTitle },
  props: {
    logicRule: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      base: 'workflow-logic',
      pre: 'knowledge.diagnostic-builder.side-bar.workflow.inputs.auto-step-selection',
      value: '',
      options: Object.freeze([
        { label: this.$t(`${pre}.logic.AND.label`), value: 'AND' },
        { label: this.$t(`${pre}.logic.OR.label`), value: 'OR' },
      ]),
    };
  },
  mounted() {
    this.value = this.logicRule.logic;
  },
  methods: {
    addRule() {
      const rules = [...this.logicRule.rules, {}];
      this.$emit('change', { ...this.logicRule, rules: rules });
    },
    removeRuleAtIdx(idx) {
      let rules = [...this.logicRule.rules];
      rules.splice(idx, 1);
      this.$emit('change', { ...this.logicRule, rules: rules });
    },
    setLogic(logic) {
      this.$emit('change', { ...this.logicRule, logic });
    },
    setRuleAtIdx(idx, rule) {
      const rules = [...this.logicRule.rules];
      rules[idx] = rule;
      this.$emit('change', { ...this.logicRule, rules });
    },
  },
};
</script>
<style lang="scss" scoped>
.workflow-logic {
  padding: 8px 0;
  &__logic {
    padding-bottom: 4px;
    &__title {
      padding: 4px;
    }
    &__rule {
      padding: 4px 0px;
    }
  }

  &__conditions {
    &__title {
      padding: 4px 4px 0px 4px;
    }
    &__rule {
      padding-bottom: 8px;
    }
    &__add {
      padding-left: 4px;
    }
  }
}
</style>
