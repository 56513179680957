<template>
  <div :class="base">
    <div :class="['mb-2']" v-for="(section, sIdx) in sections" :key="sIdx">
      <component
        v-for="el in section"
        :class="el.class || 'col-6'"
        :is="el.type"
        :key="el.key"
        v-bind="el"
        :value="inputs[el.key]"
        @change="($evt) => setPayloadKey(el, $evt)"
      />
    </div>

    <div
      :class="[`${base}__section`, { 'mt-4': idx === 0 }]"
      v-for="(el, idx) in inputs.options"
      :key="el.uuid"
    >
      <component
        :class="[`${base}__section__item`, 'mb-2']"
        v-for="field in optionSection"
        :is="field.type"
        :key="field.key"
        v-bind="field"
        :value="el[field.key]"
        @change="($evt) => setFormItem(idx, field.key, $evt)"
      />
      <font-awesome-icon
        :class="[`${base}__section__delete`]"
        :icon="['fal', 'times']"
        @click="removeFormItem(idx)"
      />
    </div>
    <div :class="`${base}__add`">
      <base-button-old
        size="sm"
        type="primary"
        :outline="true"
        @click="addFormItem()"
        >{{ $t(`${pre}.add`) }}</base-button-old
      >
    </div>
  </div>
</template>
<script>
import { v4 as uuid } from 'uuid';

import WorkflowInput from 'components/Workflow/WorkflowInput.vue';
import WorkflowSelect from 'components/Workflow/WorkflowSelect.vue';

const pre =
  'knowledge.diagnostic-builder.side-bar.workflow.inputs.set-context-from-select';

export default {
  name: 'workflow-set-context-from-select',
  components: {
    WorkflowInput,
    WorkflowSelect,
  },
  props: {
    workflowAction: {
      type: Object,
      default: () => ({}),
    },
    content: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    payload: {
      get() {
        return this.workflowAction.payload || {};
      },
      set(value) {
        this.$emit('change', { ...this.workflowAction, payload: value });
      },
    },
  },
  mounted() {
    this.syncInputs(this.payload);
  },
  data() {
    return {
      base: 'workflow-set-context-from-select',
      pre,
      sections: [
        [
          {
            title: this.$t(`${pre}.description.title`),
            info: this.$t(`${pre}.description.description`),
            placeholder: this.$t(`${pre}.description.placeholder`),
            key: 'description',
            icon: 'plug',
            type: 'WorkflowInput',
            class: 'col-12',
          },
          {
            title: this.$t(`${pre}.targetKey.title`),
            info: this.$t(`${pre}.targetKey.description`),
            placeholder: this.$t(`${pre}.targetKey.placeholder`),
            key: 'targetKey',
            icon: 'plug',
            type: 'WorkflowInput',
          },
        ],
      ],
      optionSection: [
        {
          title: this.$t(`${pre}.label.title`),
          placeholder: this.$t(`${pre}.label.placeholder`),
          key: 'label',
          icon: 'plug',
          type: 'WorkflowInput',
        },
        {
          title: this.$t(`${pre}.value.title`),
          placeholder: this.$t(`${pre}.value.placeholder`),
          key: 'value',
          icon: 'plug',
          type: 'WorkflowInput',
        },
      ],
      inputs: {
        description: null,
        targetKey: null,
        options: [],
      },
    };
  },
  methods: {
    addFormItem() {
      this.inputs.options.push({ label: null, value: null, uuid: uuid() });
    },
    removeFormItem(idx) {
      this.inputs.options.splice(idx, 1);
      this.syncPayload();
    },
    setFormItem(idx, key, value) {
      this.inputs.options[idx][key] = value;
      this.syncPayload();
    },
    setPayloadKey(el, inputValue) {
      this.inputs = Object.assign(this.inputs, {
        [el.key]: inputValue,
      });
      this.syncPayload();
    },
    syncPayload() {
      // eslint-disable-next-line no-unused-vars
      const options = [...this.inputs.options].map(({ uuid, ...el }) => el);
      this.payload = Object.assign(this.payload, { ...this.inputs, options });
    },
    syncInputs(payload) {
      if (!payload.options) {
        this.addFormItem();
      } else {
        const options = payload.options.map((el) => ({
          ...el,
          uuid: uuid(),
        }));
        this.inputs = { ...payload, options };
      }
    },
  },
  watch: {
    payload() {
      this.syncInputs(this.payload);
    },
  },
};
</script>
<style lang="scss" scoped>
.workflow-set-context-from-select {
  &__section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    &__item {
      flex: 12;
    }
    &__delete {
      flex: 1;
      margin-top: 16px;
      cursor: pointer;
      color: $grey-5-mayday;
      &:hover {
        color: $blue-mayday;
      }
    }
  }
  &__add {
    margin: 4px 4px;
  }
}
</style>
