<template>
  <IntegrationSettingsMenu
    name="Salesforce"
    :features="features"
    :preferences="preferences"
    :integration="integration.value"
  >
    <!-- SLOT INSTALLATION STEPS -->
    <IntegrationSettingsStep
      name="1"
      :title="$t('settings.integration.salesforce.step-one.title')"
      :success="state.success"
    />
    <div class="step-container">
      <div class="step-item">
        <div class="step-description">
          {{ $t('settings.integration.salesforce.step-one.description') }}
        </div>
        <ol>
          <li>{{ $t('settings.integration.salesforce.step-one.one') }}</li>
          <li>{{ $t('settings.integration.salesforce.step-one.two') }}</li>
          <li>{{ $t('settings.integration.salesforce.step-one.three') }}</li>
          <div class="step-info">
            <p v-for="id in [1, 2, 3, 4, 5]" :key="id">
              {{ $t(`settings.integration.salesforce.step-one.field-${id}`) }}
            </p>
          </div>
          <li>{{ $t('settings.integration.salesforce.step-one.four') }}</li>
        </ol>
      </div>
    </div>
    <IntegrationSettingsStep
      name="2"
      :title="$t('settings.integration.salesforce.step-two.title')"
      :success="state.success"
    />
    <div class="step-container">
      <div class="step-item">
        <ol>
          <li>{{ $t('settings.integration.salesforce.step-two.one') }}</li>
          <div class="step-input">
            <span class="label">{{
              $t('settings.integration.salesforce.step-two.instance-label')
            }}</span>
            <el-input
              :placeholder="
                state.success
                  ? '········'
                  : $t(
                      'settings.integration.salesforce.step-two.instance-placeholder',
                    )
              "
              v-model="model.instanceUrl"
              :disabled="state.success"
            >
              <font-awesome-icon
                :icon="['fas', 'link']"
                slot="prefix"
                class="step-input-icon"
                :class="[
                  {
                    'invalid-input':
                      $v.model.instanceUrl.$invalid &&
                      model.instanceUrl.length > 0,
                  },
                ]"
              />
            </el-input>
            <span class="description">
              {{
                $t('settings.integration.salesforce.step-two.instance-todo')
              }}</span
            >
          </div>
          <div class="step-input">
            <span class="label">{{
              $t(
                'settings.integration.salesforce.step-two.unique-identifier-label',
              )
            }}</span>
            <el-input
              :placeholder="
                state.success
                  ? '········'
                  : $t(
                      'settings.integration.salesforce.step-two.unique-identifier-placeholder',
                    )
              "
              v-model="model.clientId"
              :disabled="state.success"
            >
              <font-awesome-icon
                :icon="['fas', 'key']"
                slot="prefix"
                class="step-input-icon"
              />
            </el-input>
          </div>
          <div class="step-input">
            <span class="label">{{
              $t('settings.integration.salesforce.step-two.secret-label')
            }}</span>
            <el-input
              :placeholder="
                state.success
                  ? '········'
                  : $t(
                      'settings.integration.salesforce.step-two.secret-placeholder',
                    )
              "
              v-model="model.clientSecret"
              :disabled="state.success"
            >
              <font-awesome-icon
                :icon="['fas', 'lock']"
                slot="prefix"
                class="step-input-icon"
              />
            </el-input>
          </div>
          <div class="step-item">
            <base-button
              v-if="!state.success"
              :text="$t('settings.integration.salesforce.step-two.connect')"
              variant="primary"
              size="sm"
              :disabled="$v.model.$invalid"
              :loading="loader.init"
              @click="initializeSalesforceAuth()"
            ></base-button>
            <base-button
              v-else
              :text="$t('settings.integration.salesforce.step-two.modify')"
              variant="primary"
              size="sm"
              :loading="loader.delete"
              @click="deleteSalesforceAuth()"
            ></base-button>
          </div>
        </ol>
      </div>
    </div>
  </IntegrationSettingsMenu>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import IntegrationSettingsStep from '@/components/Settings/IntegrationSettings/Integrations/IntegrationSettingsStep';
import IntegrationSettingsMenu from '@/components/Settings/IntegrationSettings/Integrations/IntegrationSettingsMenu';

export default {
  name: 'integration-settings-salesforce',
  components: {
    IntegrationSettingsStep,
    IntegrationSettingsMenu,
  },
  props: {
    integration: {
      type: Object,
      required: true,
    },
  },
  computed: {
    features() {
      return this.integration.features;
    },
    integrationValue() {
      return this.integration.value;
    },
    ...mapState(['companyId']),
  },
  data() {
    return {
      preferences: {},
      model: {
        instanceUrl: '',
        clientId: '',
        clientSecret: '',
      },
      state: {
        success: false,
      },
      loader: {
        init: false,
        delete: false,
      },
    };
  },
  async mounted() {
    const { salesforce_success } = this.$route.query;

    // eslint-disable-next-line no-extra-boolean-cast
    if (!!salesforce_success) {
      this.statusMessage(salesforce_success === 'true');
    }

    this.state.success = await this.checkAuthExists(this.integrationValue);
  },
  methods: {
    ...mapActions(['initSalesforceAuth', 'checkAuthExists', 'deleteAuth']),
    async initializeSalesforceAuth() {
      this.loader.init = true;
      const { instanceUrl, clientId, clientSecret } = this.model;

      try {
        const res = await this.initSalesforceAuth({
          instanceUrl,
          clientId,
          clientSecret,
        });

        const { authorizationUrl } = res.data;

        if (authorizationUrl) {
          window.open(authorizationUrl, '_blank');
        }
      } catch (err) {
        this.statusMessage(false);
      }
      this.loader.init = false;
    },
    async deleteSalesforceAuth() {
      this.loader.delete = true;
      await this.deleteAuth(this.integrationValue);
      this.model.clientSecret = '';
      this.state.success = false;
      this.loader.delete = false;
    },
    statusMessage(success) {
      this.$message({
        message: this.$t(
          `settings.integration.${success ? 'success' : 'error'}`,
          { integration: 'Salesforce' },
        ),
        type: success ? 'success' : 'error',
      });
    },
  },
  validations: {
    model: {
      instanceUrl: {
        required,
        valid: (value) => value.includes('.salesforce.com'),
      },
      clientId: {
        required,
      },
      clientSecret: {
        required,
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.step-container {
  padding-left: 38px;

  .step-description {
    margin-bottom: 10px;
  }

  .step-item {
    color: $grey-6-mayday;
    font-size: 12px;
    margin-bottom: 10px;
    .step-action {
      display: flex;
      button {
        margin-left: 1rem;
      }
    }
    :deep() .el-input__inner {
      height: 34px;
    }
    ol {
      padding-left: 12px;
    }
    .step-info {
      border: 1px solid $grey-5-mayday;
      background-color: $grey-1-mayday;
      color: $grey-8-mayday;
      padding: 6px 10px;
      margin: 8px 0;
      p {
        font-size: 12px;
        margin-bottom: 0;
        font-weight: bold;
      }
    }
    .step-input {
      margin-bottom: 10px;
      .label {
        font-weight: bold;
        color: black;
        padding: 8px 0px;
      }
      .step-input-icon {
        width: 16px;
        height: 16px;
        margin: 9px 4px;
      }
      .description {
        font-size: 12px;
        color: black;
      }
    }

    .invalid-input {
      color: rgba($red-mayday, 0.4);
    }
  }
}
</style>
