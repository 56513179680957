<template>
  <BaseModal
    custom-width="400px"
    custom-height="400px"
    button-size="md"
    :sidebar="false"
    :title="
      isMonolingual
        ? $t(
            'public-knowledge.configuration.items.language.add-language-modal.title-monolingual',
          )
        : $t(
            'public-knowledge.configuration.items.language.add-language-modal.title-multilingual',
          )
    "
    :description="
      isMonolingual
        ? $t(
            'public-knowledge.configuration.items.language.add-language-modal.description-monolingual',
          )
        : $t(
            'public-knowledge.configuration.items.language.add-language-modal.description-multilingual',
          )
    "
    :open="localDisplay"
    :confirm-text="$t('components.modals.update-user-label.confirm-button')"
    :disabled="!selectedValue"
    @cancel="$emit('close')"
    @confirm="handleAddLanguage"
    @close="$emit('close')"
  >
    <div class="flex-column d-flex justify-content-start subcontent">
      <p class="my-3 px-3">
        {{
          isMonolingual
            ? $t(
                'public-knowledge.configuration.items.language.add-language-modal.action-set-multilingual',
              )
            : $t(
                'public-knowledge.configuration.items.language.add-language-modal.action',
              )
        }}
      </p>
      <div class="mb-4 text-center">
        <el-select
          v-model="selectedValue"
          class="col-12 label-select"
          popper-class="language-popover-container"
        >
          <el-option
            v-for="lang in filteredOptions"
            :key="lang"
            :value="lang"
            :label="$t(`knowledge.languages.${lang}`)"
          >
          </el-option>
        </el-select>
      </div>
    </div>
  </BaseModal>
</template>

<script>
import { langOptions } from '@/utils/langOptions.js';

export default {
  components: {},
  props: {
    display: {
      type: Boolean,
      required: true,
    },
    isMonolingual: {
      type: Boolean,
      default: false,
    },
    existingLanguages: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      localDisplay: this.display,
      selectedValue: '',
      options: langOptions,
    };
  },
  computed: {
    filteredOptions() {
      return this.options.filter((n) => !this.existingLanguages.includes(n));
    },
  },
  watch: {
    localDisplay() {
      if (!this.localDisplay) this.$emit('close');
    },
  },
  methods: {
    handleAddLanguage() {
      const event = this.isMonolingual ? 'set-multilingual' : 'new-language';
      this.$emit(event, this.selectedValue);
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
.blue-mayday {
  color: $blue-mayday;
}

.icon {
  width: 24px;
  height: 24px;
}

.subcontent {
  border: 1px solid $grey-3-mayday;
  border-radius: 2px;
  height: 75%;
}
</style>
