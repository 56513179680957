<template>
  <div class="d-flex flex-row justify-content-start mt-2">
    <el-input
      :placeholder="textValue"
      v-model="textValue"
      size="mini"
      class="text-copy-input mr-2"
      :style="`width : ${element.width}`"
      :readonly="true"
    >
      <template slot="append">{{ element.append }}</template>
    </el-input>
    <base-button
      variant="tertiary"
      size="sm"
      @click="notifyCopy"
      v-clipboard:copy="elementValueDisplay"
      :text="$t('public-knowledge.configuration.items.domain.copy-button')"
    ></base-button>
  </div>
</template>

<script>
export default {
  name: 'TextCopyInput',
  data() {
    return { textValue: '' };
  },
  props: {
    element: Object,
    elementValueDisplay: String,
    isEmpty: Boolean,
  },
  mounted() {
    this.textValue = this.elementValueDisplay;
    this.textValue = this.textValue.replace(`.${this.element.append}`, '');
  },
  methods: {
    newValue(e) {
      this.$emit('new-element', e);
    },
    notifyCopy() {
      this.$message({
        message: this.$t(
          'knowledge.actions.copy-to-clipboard-confirmation-message',
        ),
        type: 'success',
      });
    },
  },
  watch: {
    elementValueDisplay() {
      this.textValue = this.elementValueDisplay;
      this.textValue = this.textValue.replace(`.${this.element.append}`, '');
    },
  },
};
</script>

<style lang="scss" scoped>
.text-copy-input {
  font-size: 12px;
  height: 24.2px;
}
.copy-button {
  width: 100px;
}
:deep() .el-input .el-input__inner {
  height: 100%;
}
</style>
