<template>
  <IntegrationSettingsMenu
    name="Chrome"
    :features="features"
    :preferences="preferences"
    :integration="integration.value"
  >
    <!-- SLOT INSTALLATION STEPS -->
    <IntegrationSettingsStep
      name="1"
      :title="$t('settings.integration.chrome.step-one.title')"
    />
    <div class="step-container">
      <div class="step-item">
        <div class="step-text">
          {{ $t('settings.integration.chrome.step-one.todo') }}
          <a
            href="https://www.loom.com/share/2bc9a868aa06427088fe94b165a14ade"
            target="_blank"
          >
            {{ $t('settings.integration.chrome.step-one.todo-link') }}
          </a>
          🎬
        </div>
      </div>
      <div class="step-item">
        <base-button
          :text="$t('settings.integration.chrome.step-one.install')"
          variant="primary"
          size="sm"
          @click="
            openLink(
              'https://chrome.google.com/webstore/detail/mayday/eloemnahbeeiekllcfeombpkokhinkpn',
            )
          "
        ></base-button>
      </div>
    </div>
  </IntegrationSettingsMenu>
</template>

<script>
import IntegrationSettingsStep from '@/components/Settings/IntegrationSettings/Integrations/IntegrationSettingsStep';
import IntegrationSettingsMenu from '@/components/Settings/IntegrationSettings/Integrations/IntegrationSettingsMenu';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'integration-settings-chrome',
  components: {
    IntegrationSettingsStep,
    IntegrationSettingsMenu,
  },
  props: {
    integration: {
      type: Object,
      required: true,
    },
  },
  computed: {
    features() {
      return this.integration.features;
    },
    ...mapGetters([
      'companyColor',
      'companyWebExtPrimaryColor',
      'companyWebExtSecondaryColor',
      'companyWebExtIcon',
      'companyLogo',
      'isPluginPreferenceAllowed',
    ]),
  },
  data() {
    return {
      pluginIntegrationName: 'Widget',
      preferences: {},
    };
  },
  methods: {
    openLink(url) {
      window.open(url, '_blank');
    },
    ...mapActions('adminModule', ['getMe']),
  },
  mounted() {
    this.preferences = {
      WEB_EXT_SEARCH_NO_STEPS: {
        title: this.$t(
          'settings.card-settings-options.integration-search.title',
        ),
        description: this.$t(
          'settings.card-settings-options.integration-search.description',
        ),
        iconName: ['fas', 'search'],
        notification: this.$t(
          'settings.card-settings-options.integration-search.notification',
        ),
        hasRule: false,
      },
      WEB_EXT_AUTOMATION_DISPLAY_DELAY: {
        title: this.$t(
          'settings.card-settings-options.integration-automation.title',
        ),
        description: this.$t(
          'settings.card-settings-options.integration-automation.description',
        ),
        iconName: ['fas', 'stopwatch-20'],
        timeRange: this.$t(
          'settings.card-settings-options.integration-automation.time-range',
        ),
        defaultValue: 15,
        rule: this.$t(
          'settings.card-settings-options.integration-automation.rule',
        ),
        notification: this.$t(
          'settings.card-settings-options.integration-automation.notification',
        ),
        hasRule: true,
      },
      WEB_EXT_PRIMARY_COLOR: {
        title: this.$t(
          'settings.card-settings-options.integration-primary-color.title',
        ),
        description: `${this.$t(
          'settings.card-settings-options.integration-primary-color.description',
        )}  ${this.$t('settings.integration.chrome.inlineLabel')}`,
        isColor: true,
        defaultValue: this.companyColor,
        value: this.companyWebExtPrimaryColor,
        iconName: ['fas', 'palette'],
        notification: this.$t(
          'settings.card-settings-options.integration-primary-color.notification',
        ),
      },
      WEB_EXT_SECONDARY_COLOR: {
        title: this.$t(
          'settings.card-settings-options.integration-secondary-color.title',
        ),
        description: `${this.$t(
          'settings.card-settings-options.integration-secondary-color.description',
        )}  ${this.$t('settings.integration.chrome.inlineLabel')}`,
        isColor: true,
        defaultValue: this.companyColor,
        value: this.companyWebExtSecondaryColor,
        iconName: ['fas', 'palette'],
        notification: this.$t(
          'settings.card-settings-options.integration-secondary-color.notification',
        ),
      },
      WEB_EXT_ICON: {
        title: this.$t('settings.card-settings-options.integration-icon.title'),
        description: `${this.$t(
          'settings.card-settings-options.integration-icon.description',
        )}  ${this.$t('settings.integration.chrome.inlineLabel')}`,
        iconName: ['fas', 'image'],
        notification: this.$t(
          'settings.card-settings-options.integration-icon.notification',
        ),
        isImage: true,
        hasRule: false,
        defaultValue: this.companyWebExtIcon || this.companyLogo,
      },
      ALLOW_EMBEDDED_IFRAME: {
        title: this.$t(
          'settings-layout.menu-data.plugins.embedded-iframe.title',
        ),
        description: `${this.$t(
          'settings-layout.menu-data.plugins.embedded-iframe.short-description',
        )}`,
        iconName: ['fal', 'code'],
        value: this.integrationStatus,
        hasRule: false,
        pluginIntegration: this.pluginIntegrationName,
        disabled: !this.isPluginPreferenceAllowed('ALLOW_EMBEDDED_IFRAME'),
        pluginType: 'EmbeddedIframeEntity',
      },
      ALLOW_TRENDING_CONTENT: {
        title: this.$t(
          'settings.card-settings-options.common.plugin.trending-contents.title',
        ),
        description: `${this.$t(
          'settings.card-settings-options.common.plugin.trending-contents.description',
        )}`,
        iconName: ['fal', 'arrow-trend-up'],
        value: this.integrationStatus,
        hasRule: false,
        pluginIntegration: this.pluginIntegrationName,
        disabled: !this.isPluginPreferenceAllowed('ALLOW_TRENDING_CONTENT'),
        pluginType: 'TrendingContentEntity',
      },
      DESK_NEW_CONTENTS_PLUGIN: {
        title: this.$t(
          'settings.card-settings-options.common.plugin.new-contents.title',
        ),
        description: `${this.$t(
          'settings.card-settings-options.common.plugin.new-contents.description',
        )}`,
        iconName: ['fas', 'align-left'],
        notification: this.$t(
          'settings.card-settings-options.common.plugin.new-contents.notification',
        ),
        value: this.integrationStatus,
        hasRule: false,
        pluginIntegration: this.pluginIntegrationName,
        disabled: !this.isPluginPreferenceAllowed('DESK_NEW_CONTENTS_PLUGIN'),
        pluginType: 'NewContentEntity',
      },
      ALLOW_SMART_ACTIONS: {
        title: this.$t(
          'settings.card-settings-options.common.plugin.smart-actions.title',
        ),
        description: `${this.$t(
          'settings.card-settings-options.common.plugin.smart-actions.description',
        )}`,
        iconName: ['fas', 'arrow-up-right-from-square'],
        notification: this.$t(
          'settings.card-settings-options.common.plugin.smart-actions.notification',
        ),
        value: this.integrationStatus,
        hasRule: false,
        pluginIntegration: this.pluginIntegrationName,
        disabled: !this.isPluginPreferenceAllowed('ALLOW_SMART_ACTIONS'),
        pluginType: 'SmartActionEntity',
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.step-container {
  padding-left: 38px;

  .step-item {
    color: $grey-6-mayday;
    font-size: 12px;
    margin-bottom: 10px;
    .step-action {
      display: flex;
      button {
        margin-left: 1rem;
      }
    }
    :deep() .el-input__inner {
      height: 34px;
    }
    ol {
      padding-left: 12px;
    }
    .step-info {
      border: 1px solid $grey-5-mayday;
      background-color: $grey-1-mayday;
      color: $grey-8-mayday;
      padding: 6px 10px;
      margin: 0;
      p {
        font-size: 12px;
        margin-bottom: 0;
        font-weight: bold;
      }
    }
    .step-input {
      margin-bottom: 10px;
      .label {
        font-weight: bold;
        color: black;
        padding: 8px 0px;
      }
      .step-input-icon {
        width: 16px;
        height: 16px;
        margin: 9px 4px;
      }
      .description {
        font-size: 12px;
        color: black;
      }
    }
  }
}
</style>
