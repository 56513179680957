<template>
  <IntegrationSettingsMenu
    name="Intercom"
    :features="features"
    :preferences="preferences"
    :integration="integration.value"
  >
    <!-- SLOT INSTALLATION STEPS -->
    <IntegrationSettingsStep
      name="1"
      :title="$t('settings.integration.intercom.title')"
      :success="state.success"
    />
    <div class="step-container">
      <div class="step-item">
        <div class="step-text">
          {{ $t('settings.integration.intercom.todo') }}
        </div>
        <base-button
          :text="$t('settings.integration.intercom.button')"
          :icon="intercomIcon"
          variant="primary"
          size="sm"
          class="my-2"
          @click="openLink(intercomOauthUrl)"
          :disabled="!hasIntercom || state.success"
        >
        </base-button>

        <div class="step-text">
          {{ $t('settings.integration.intercom.finished') }}
        </div>
      </div>
    </div>
  </IntegrationSettingsMenu>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import IntegrationSettingsStep from '@/components/Settings/IntegrationSettings/Integrations/IntegrationSettingsStep';
import IntegrationSettingsMenu from '@/components/Settings/IntegrationSettings/Integrations/IntegrationSettingsMenu';

export default {
  name: 'integration-settings-intercom',
  components: {
    IntegrationSettingsStep,
    IntegrationSettingsMenu,
  },
  props: {
    integration: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState(['companyId']),
    ...mapGetters(['hasIntercom']),
    intercomOauthUrl() {
      return `https://app.intercom.io/oauth?client_id=39981fc3-172e-41b0-90ca-7e7329dcc497&redirect_uri=${process.env.VUE_APP_BACK_URL}/auth/intercom&state=${this.companyId}`;
    },
    features() {
      return this.integration.features;
    },
    intercomIcon() {
      return JSON.stringify(['fab', 'intercom']);
    },
  },
  data() {
    return {
      preferences: {},
      state: {
        success: false,
      },
    };
  },
  methods: {
    openLink(url) {
      window.open(url, '_blank');
    },
    ...mapActions(['checkAuthExists']),
  },
  async mounted() {
    const authOk = await this.checkAuthExists('intercom');
    if (authOk) this.state.success = true;
  },
};
</script>

<style lang="scss" scoped>
.disabled {
  cursor: not-allowed;
  opacity: 0.5;
  .pointer {
    pointer-events: none;
  }
}
.step-container {
  padding-left: 38px;

  .step-item {
    color: $grey-6-mayday;
    font-size: 12px;
    margin-bottom: 10px;
    .step-action {
      display: flex;
      button {
        margin-left: 1rem;
      }
    }
    :deep() .el-input__inner {
      height: 34px;
    }
    ol {
      padding-left: 12px;
    }
    .step-info {
      border: 1px solid $grey-5-mayday;
      background-color: $grey-1-mayday;
      color: $grey-8-mayday;
      padding: 6px 10px;
      margin: 0;
      p {
        font-size: 12px;
        margin-bottom: 0;
        font-weight: bold;
      }
    }
    .step-input {
      margin-bottom: 10px;
      .label {
        font-weight: bold;
        color: black;
        padding: 8px 0px;
      }
      .step-input-icon {
        width: 16px;
        height: 16px;
        margin: 9px 4px;
      }
      .description {
        font-size: 12px;
        color: black;
      }
    }
  }
}
</style>
