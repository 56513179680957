var render = function render(){var _vm=this,_c=_vm._self._c;return _c('BaseModal',{attrs:{"custom-width":"400px","custom-height":"400px","button-size":"md","sidebar":false,"title":_vm.isMonolingual
      ? _vm.$t(
          'public-knowledge.configuration.items.language.add-language-modal.title-monolingual',
        )
      : _vm.$t(
          'public-knowledge.configuration.items.language.add-language-modal.title-multilingual',
        ),"description":_vm.isMonolingual
      ? _vm.$t(
          'public-knowledge.configuration.items.language.add-language-modal.description-monolingual',
        )
      : _vm.$t(
          'public-knowledge.configuration.items.language.add-language-modal.description-multilingual',
        ),"open":_vm.localDisplay,"confirm-text":_vm.$t('components.modals.update-user-label.confirm-button'),"disabled":!_vm.selectedValue},on:{"cancel":function($event){return _vm.$emit('close')},"confirm":_vm.handleAddLanguage,"close":function($event){return _vm.$emit('close')}}},[_c('div',{staticClass:"flex-column d-flex justify-content-start subcontent"},[_c('p',{staticClass:"my-3 px-3"},[_vm._v(" "+_vm._s(_vm.isMonolingual ? _vm.$t( 'public-knowledge.configuration.items.language.add-language-modal.action-set-multilingual', ) : _vm.$t( 'public-knowledge.configuration.items.language.add-language-modal.action', ))+" ")]),_c('div',{staticClass:"mb-4 text-center"},[_c('el-select',{staticClass:"col-12 label-select",attrs:{"popper-class":"language-popover-container"},model:{value:(_vm.selectedValue),callback:function ($$v) {_vm.selectedValue=$$v},expression:"selectedValue"}},_vm._l((_vm.filteredOptions),function(lang){return _c('el-option',{key:lang,attrs:{"value":lang,"label":_vm.$t(`knowledge.languages.${lang}`)}})}),1)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }