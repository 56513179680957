var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:['d-flex flex-row mt-1', _vm.isEmpty ? '' : _vm.element.deleteWrapperAlign]},[_c('div',{class:[
      'img-placeholder-wrapper',
      { 'img-placeholder-wrapper-empty': _vm.isEmpty },
    ],style:([_vm.element.style, _vm.isEmpty ? _vm.element.emptyStyle : '']),on:{"click":function($event){_vm.modals.img.display = true},"mouseover":function($event){return _vm.$emit('hover', true)},"mouseleave":function($event){return _vm.$emit('hover', false)}}},[_c('el-tooltip',{attrs:{"content":_vm.$t('public-knowledge.appearances.items.brand.tooltip-download'),"disabled":_vm.isEmpty}},[_c('img',{staticClass:"img-placeholder",style:(_vm.isEmpty ? _vm.element.imgEmptyStyle : ''),attrs:{"src":_vm.elementValueDisplay}})])],1),_c('div',{class:[
      'd-flex flex-column ml-2 justify-content-between',
      { 'download-group': _vm.isEmpty },
    ]},[(_vm.isEmpty)?_c('base-button',{attrs:{"variant":"primary","size":"sm","text":_vm.$t('public-knowledge.appearances.items.brand.button-download')},on:{"click":function($event){_vm.modals.img.display = true}}}):_c('el-popconfirm',{attrs:{"confirm-button-text":_vm.$t('public-knowledge.appearances.items.brand.confirm-delete-true'),"cancel-button-text":_vm.$t('public-knowledge.appearances.items.brand.confirm-delete-false'),"icon":"el-icon-info","icon-color":"red","title":_vm.$t('public-knowledge.appearances.items.brand.confirm-delete')},on:{"confirm":function($event){return _vm.$emit('delete-element', $event)}}},[_c('el-tooltip',{attrs:{"slot":"reference","content":_vm.$t('public-knowledge.appearances.items.brand.tooltip-delete')},slot:"reference"},[_c('base-button',{staticClass:"display-on-hover",attrs:{"icon-only":true,"icon":"trash","variant":"tertiary","size":"sm"}})],1)],1),(_vm.isEmpty)?_c('div',{staticClass:"img-input-advise"},[_vm._v(" "+_vm._s(_vm.$t('public-knowledge.appearances.items.brand.download-advice', { width: _vm.element.size.width, height: _vm.element.size.height, }))+" ")]):_vm._e()],1),(_vm.modals.img.display)?_c('IconEditionModal',{attrs:{"title":_vm.element.modalTitle,"description":_vm.element.modalDescription,"display":_vm.modals.img.display,"editionOptions":_vm.modals.img.editionOptions,"aspect-ratio":_vm.element.label === 'companyIconUri' ? null : _vm.modals.img.aspectRatio,"custom-width":"400px","custom-height":"400px"},on:{"close":function($event){_vm.modals.img.display = false},"choice":_vm.newValue}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }