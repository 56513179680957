<template>
  <div
    v-loading="true"
    class="d-flex h-100 w-100 justify-content-center align-items-center full-screen"
  ></div>
</template>

<script>
export default {
  name: 'NotificationCenter',
  mounted() {
    window.location.href = `${window.location.origin}/notifications`;
  },
};
</script>

<style lang="scss" scoped>
.full-screen {
  height: 100vh !important;
}
</style>
